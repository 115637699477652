<script setup lang="ts">
  const layoutCustomProps = useAttrs();
  const canBack = layoutCustomProps.back ?? false;

  const onClickBack = () => {
    useRouter().back();
  };
</script>

<template>
  <div>
    <NuxtLayout>
      <div class="mx-auto" style="width: 90%">
        <v-icon v-if="canBack" data-testid="back-button" size="40px" class="back" @click="onClickBack"
          >$mdiChevronLeft</v-icon
        >
        <div class="d-flex flex-row justify-center">
          <NuxtLink to="/">
            <AtomsFincsLogoSvg height="30" class="mt-8" />
          </NuxtLink>
        </div>
      </div>
      <div class="mx-auto" width="90%">
        <slot name="header"></slot>
      </div>
      <slot></slot>
    </NuxtLayout>
  </div>
</template>

<style lang="scss" scoped>
  .back {
    position: absolute;
    top: 48px;
  }
</style>
